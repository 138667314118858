const Logo = [
  `
      ▄▄▄▄     ▄▄▄▄     ▄▄▄▄
▄▄▄▄▄▄█  █▄▄▄▄▄█  █▄▄▄▄▄█  █
█__ --█  █__ --█    ◄█  -  █
█▄▄▄▄▄█▄▄█▄▄▄▄▄█▄▄█▄▄█▄▄▄▄▄█
`,
  `
        ▄▄▄▄         ▄▄▄▄       ▄▄▄▄
▄▄▄▄▄▄▄ █  █ ▄▄▄▄▄▄▄ █  █▄▄▄ ▄▄▄█  █
█__ --█ █  █ █__ --█ █    ◄█ █  -  █
█▄▄▄▄▄█ █▄▄█ █▄▄▄▄▄█ █▄▄█▄▄█ █▄▄▄▄▄█
`,
];

export default Logo;
